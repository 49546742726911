.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.App-body {
  flex: 1;
}

.App-footer {
  flex-shrink: 0;
}
